<template>
  <div>
    <FAQ />
  </div>
</template>

<script>
import FAQ from '@/components/HomeComponents/FAQ.vue'

export default {
  components: {
    FAQ,
  },
}
</script>
